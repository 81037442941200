import store from '@/store';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {applicationStore} from '@/store/modules/application';
import {BusinessContact, BusinessDepartment} from '@/domain/model/types';
import {CreateCustomContactUseCase} from '@/domain/createCustomContactUseCase';
import {container} from 'tsyringe';
import {CreateGroupContactUseCase} from '@/domain/createGroupContactUseCase';
import {AddContactToGroupUseCase} from '@/domain/addContactToGroupUseCase';
import {businessDirectory, businessDirectoryContact, user, userAppointDays, userBookedDates} from '@/data/firebase';
import {deleteDoc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where, writeBatch} from 'firebase/firestore';
import {firestore} from '@/plugins/firebase.init';

interface DirectoryState {
  loading: boolean
  message: string
  customContacts: BusinessContact[]
  groupContacts: BusinessContact[]

  createCustomContact(data: any)
}

@Module({dynamic: true, store, name: 'directory-store'})
export default class DirectoryStore extends VuexModule implements DirectoryState {
  private _loading: boolean = false;
  private _message: string = '';
  private _customContacts: BusinessContact[] = [];
  private _groupContacts: BusinessContact[] = [];
  private _selectedContactAssignmentGroups: BusinessContact[] = [];
  private _departmentsSate: any = {}

  private createContact: CreateCustomContactUseCase = container.resolve(CreateCustomContactUseCase)
  private createGroupContactUseCase: CreateGroupContactUseCase = container.resolve(CreateGroupContactUseCase)
  private addContactToGroupUseCase: AddContactToGroupUseCase = container.resolve(AddContactToGroupUseCase)

  get departmentsState() {
    return this._departmentsSate
  }

  get loading() {
    return this._loading;
  }

  get busy() {
    return this._loading;
  }

  get infoMessage() {
    return this.message;
  }

  get message() {
    return this._message;
  }

  get allContacts() {
    return [...this._customContacts, ...this._groupContacts]
  }

  get customContacts() {
    return this._customContacts;
  }

  get groupContacts() {
    return this._groupContacts;
  }

  get directoryContactsCount() {
    return this._customContacts.length + this._groupContacts.length
  }

  get contactsOnline() {
    const onlineFnc = (item) => !!(item.associate && item.associate.status && item.associate.status.online);
    const onlineGrpFnc = (item) => !!(item.contacts && item.contacts.find(onlineFnc));
    return this._customContacts.filter(onlineFnc).length + this._groupContacts.filter(onlineGrpFnc).length;
  }

  get selectedContactAssignmentGroups() {
    return this._selectedContactAssignmentGroups
  }

  @Mutation
  public setCustomContacts(customContacts: BusinessContact[]) {
    this._customContacts = customContacts;
  }

  @Mutation
  public addCustomContact(contact: any) {
    if (this._customContacts.findIndex((item) => item.id === contact.id) === -1) {
      this._customContacts.push(contact);
    }
  }

  @Mutation
  public setGroupContacts(groupContacts: BusinessContact[]) {
    this._groupContacts = groupContacts;
  }

  @Mutation
  public addGroupContact(contact: any) {
    if (this._groupContacts.findIndex((item) => item.id === contact.id) === -1) {
      this._groupContacts.push(contact);
    }
  }

  @Mutation
  public setLoading(value: boolean) {
    this._loading = value;
  }

  @Mutation
  public setMessage(message: string) {
    this._message = message;
  }

  @Action
  public async createCustomContact({associate, contactData, defaultContact}) {
    let contact: BusinessContact | null = null
    this.setLoading(true);
    try {
      contact = await this.createContact.invoke(this.context.rootGetters.business, associate, contactData);
      if (!associate.defaultContactId || defaultContact) {
        // todo: move to use case
        await updateDoc(user(contact!.associate!.id!), 'defaultContactId', contact!.id);
      }
      this.setMessage(`Contact has been created: ${contact!.name}`);

    } catch (err) {
      console.error(err);
    }
    this.setLoading(false);
    return contact
  }

  @Action
  public async createGroupContact({contacts, contactData}) {
    let contact: BusinessContact | null = null

    this.setLoading(true);
    try {
      contact = await this.createGroupContactUseCase.invoke(contacts, contactData);
      this.setMessage(`Group Contact has been created: ${contact!.name}`);
    } catch (err) {
      console.error(err);
    }
    this.setLoading(false);
    return contact
  }

  @Action
  public async addContactToGroup({groupContact, newContacts}) {
    this.setLoading(true);
    try {
      const contact = await this.addContactToGroupUseCase.invoke(groupContact, newContacts);
      this.setMessage(`Contact has been added to group: ${contact!.name}`);
    } catch (err) {
      console.error(err);
    }
    this.setLoading(false);
  }

  @Action
  public async changeContactVisibility({id, visible}) {
    try {
      await updateDoc(businessDirectoryContact(this.context.rootGetters.business.id, id), 'rules.VISIBILITY.visible', visible);
      this.setMessage('Contact\'s visibility has been changed');
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public loadContacts() {
    try {
      const businessId = applicationStore.business!.id;
      if (!businessId) {
        return null;
      }
      this.setCustomContacts([]);
      this.setGroupContacts([]);
      const contactsQuery = query(businessDirectory(businessId),
        where('type', '<', 3),
        orderBy('type'),
        orderBy('name'))
      return onSnapshot(contactsQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const contact = change.doc.data();
          contact.id = change.doc.id;
          switch (change.type) {
            case 'added': {
              if (contact.type === 2) {
                this.addGroupContact(contact);
              } else {
                this.addCustomContact(contact);
              }
              break;
            }
            case 'modified': {
              if (contact.type === 2) {
                this.updateGroupContact(contact);
              } else {
                this.updateCustomContact(contact);
              }
              break;
            }
            case 'removed': {
              if (contact.type === 2) {
                this.removeGroupContact(contact.id);
              } else {
                this.removeCustomContact(contact.id);
              }
              break;
            }
            default:
              console.log('Change unrecognized');
          }
        });
        console.log('contacts loaded');
      });
    } catch (err) {
      console.error(err);
    }
    return null;
  }

  // todo: revise logic
  @Action
  public async deleteContact2(contactId: string) {
    try {
      // todo: move to use case
      const businessId = applicationStore.businessId!;
      const directoryOriginals = this.context.rootGetters.directoryOriginals;
      directoryOriginals.splice(directoryOriginals.findIndex((contact) => contact.id === contactId), 1);
      // recalculate indexes
      const alteredContacts = directoryOriginals.filter((value, index) => {
        const altered = value.flatIndex !== index;
        if (altered) {
          value.flatIndex = index;
        }
        return altered;
      });
      await deleteDoc(businessDirectoryContact(businessId, contactId));
      const batch = writeBatch(firestore);
      for (const item of alteredContacts) {
        batch.update(businessDirectoryContact(businessId, item.id!),
          'flatIndex', item.flatIndex,
          'path', item.path || []);
      }
      await batch.commit();
      this.setMessage('Contact has been deleted');
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async deleteDepartment2(department: BusinessDepartment) {
    try {
      // todo: move to use case
      const businessId = applicationStore.businessId!;
      const directoryOriginals = this.context.rootGetters.directoryOriginals;
      const itemsToDelete: any[] = directoryOriginals
        .filter((item) => item.name === department.name || (!!item.path && item.path.includes(department.name)));
      for (const item of itemsToDelete) {
        directoryOriginals.splice(directoryOriginals.indexOf(item), 1);
      }
      // recalculate indexes
      const alteredContacts = directoryOriginals.filter((value, index) => {
        const altered = value.flatIndex !== index;
        if (altered) {
          value.flatIndex = index;
        }
        return altered;
      });
      const batch = writeBatch(firestore);
      for (const item of itemsToDelete) {
        batch.delete(businessDirectoryContact(businessId, item.id!));
      }
      for (const item of alteredContacts) {
        batch.update(businessDirectoryContact(businessId, item.id!),
          'flatIndex', item.flatIndex,
          'path', item.path || []);
      }
      await batch.commit();
      this.setMessage('Department has been deleted');
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async loadContactById(contactId: any) {
    const contact = await this.getContactById(contactId)
    await this.context.dispatch('selectContact', contact)
  }

  @Action
  public async getContactById(contactId: any): Promise<BusinessContact | undefined> {
    let contact = this.customContacts.find((item) => item.id === contactId)
    contact = !contact ? this.groupContacts.find((item) => item.id === contactId) : contact
    if (!contact) {
      const businessId = applicationStore.businessId!;
      const snapshot = await getDoc(businessDirectoryContact(businessId, contactId))
      if (snapshot.exists()) {
        contact = {id: snapshot.id, ...snapshot.data()} as BusinessContact
      }
    }
    return contact
  }

  @Mutation
  public updateGroupContact(contact: any) {
    const index = this._groupContacts.findIndex((item) => item.id === contact.id);
    this._groupContacts.splice(index, 1, contact);
  }

  @Mutation
  public updateCustomContact(contact: any) {
    const index = this._customContacts.findIndex((item) => item.id === contact.id);
    this._customContacts.splice(index, 1, contact);
  }

  @Mutation
  public removeGroupContact(contactId: string) {
    const index = this._groupContacts.findIndex((item) => item.id === contactId)
    this._groupContacts.splice(index, 1);
  }

  @Mutation
  public removeCustomContact(contactId: string) {
    const index = this._customContacts.findIndex((item) => item.id === contactId)
    this._customContacts.splice(index, 1);
  }

  @Action
  public async loadAppointDays(associateId: string) {
    const snapshot = await getDocs(userAppointDays(associateId));
    return snapshot.docs.map((doc) => doc.data())
  }

  @Action
  public async loadBookedDates({associateId, from, to}) {
    console.log(`loadBookedDates => from:${from}, to:${to}`)
    try {
      const bookQuery = query(userBookedDates(associateId),
        where('date', '>=', from),
        where('date', '<=', to),
        orderBy('date'))
      const snapshot = await getDocs(bookQuery);
      return snapshot.docs.map((doc) => {
        const date = doc.data().date.toDate()
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours < 12 ? hours : hours - 12}:${minutes < 10 ? `0${minutes}` : minutes} ${hours < 12 ? 'AM' : 'PM'}`
      })
    } catch (err) {
      console.error(err)
      console.log(err)
    }
    return []
  }

  @Mutation
  public loadAssignedGroupContacts(contactId?: string) {
    if (!contactId) {
      return
    }
    this._selectedContactAssignmentGroups = this._groupContacts
      .filter((contact) => !!contact.contacts?.find((item) => item.id === contactId));
  }

  @Mutation
  public togglePanelState(contactId: string) {
    const currentState = this._departmentsSate[contactId];
    this._departmentsSate[contactId] = !currentState ? -1 : 0
  }
}

export const directoryStore = getModule(DirectoryStore);
