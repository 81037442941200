import store from '@/store';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import axios from '@/plugins/axios';
import {appConfig, dynamicLinkConfig} from '@/plugins/firebase.config';
import {auth, database, firebaseApp, firestore} from '@/plugins/firebase.init';
import {
  deleteDoc,
  deleteField,
  getDoc,
  getDocs,
  onSnapshot,
  QuerySnapshot,
  setDoc,
  Timestamp,
  Unsubscribe,
  updateDoc,
  writeBatch
} from 'firebase/firestore';
import {EmailAuthProvider, reauthenticateWithCredential, updatePassword} from 'firebase/auth'
import {off, onDisconnect, onValue, ref, serverTimestamp, update} from 'firebase/database'
import {AppointDay, AssociateAccount, User} from '@/domain/model/types';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import rfdc from 'rfdc';
import {container} from 'tsyringe';
import {UnblockUserRequest, UnblockUserUseCase} from '@/domain/unblockUserUseCase';
import {notificationsStore} from '@/store/modules/notifications';
import router from '@/router';
import {applicationStore} from '@/store/modules/application';
import {
  user,
  userAppointDay,
  userAppointDayByRef,
  userAppointDays,
  userAssistant,
  userAssistants
} from '@/data/firebase';
import deleteProperty = Reflect.deleteProperty;

const clone = rfdc({proto: true})

const unblockUser = container.resolve<UnblockUserUseCase>('UnblockUser')

const daysOrder = {sunday: 1, monday: 2, tuesday: 3, wednesday: 4, thursday: 5, friday: 6, saturday: 7}
const appointDaysDefault: AppointDay[] = [
  {
    name: 'sunday',
    from: '9:00 AM',
    to: '6:00 PM',
    start: '9:00 AM',
    end: '6:00 PM',
    active: false,
    period: 30
  },
  {
    name: 'monday',
    from: '9:00 AM',
    to: '6:00 PM',
    start: '9:00 AM',
    end: '6:00 PM',
    active: false,
    period: 30
  },
  {
    name: 'tuesday',
    from: '9:00 AM',
    to: '6:00 PM',
    start: '9:00 AM',
    end: '6:00 PM',
    active: false,
    period: 30
  },
  {
    name: 'wednesday',
    from: '9:00 AM',
    to: '6:00 PM',
    start: '9:00 AM',
    end: '6:00 PM',
    active: false,
    period: 30
  },
  {
    name: 'thursday',
    from: '9:00 AM',
    to: '6:00 PM',
    start: '9:00 AM',
    end: '6:00 PM',
    active: false,
    period: 30
  },
  {
    name: 'friday',
    from: '9:00 AM',
    to: '6:00 PM',
    start: '9:00 AM',
    end: '6:00 PM',
    active: false,
    period: 30
  },
  {
    name: 'saturday',
    from: '9:00 AM',
    to: '6:00 PM',
    start: '9:00 AM',
    end: '6:00 PM',
    active: false,
    period: 30
  }
]
const defaultUser: AssociateAccount = {
  email: '',
  fullName: '',
  position: '',
  phoneNumber: '',
  photoUrl: null,
  awaySettings: {useMessage: false, message: ''},
  workingStatus: {
    autocancel: false,
    duration: 0,
    time: null,
    name: '',
    type: 0
  },
  status: {
    online: false,
    lastChanged: null
  },
  business: {
    id: '',
    name: ''
  },
  defaultContactId: null,
  description: null,
  admin: false,
  superAdmin: false,
  owner: false,
  stats: {
    cases: 0,
    rating: 0,
    avgRating: 0
  },
  roles: {
    associate: true,
    admin: false,
    superAdmin: false
  },
  doNotDisturb: null,
  disableAppoint: null,
  firstVisit: null
}

@Module({name: 'profile-module', store, dynamic: true})
export default class ProfileModule extends VuexModule {
  private _currentUser: User | null = {
    uid: null,
    displayName: null,
    photoURL: null
  };
  private _t2bUser: AssociateAccount = clone(defaultUser);
  private _saving: boolean = false
  private _busy: boolean = false
  private _error: string = ''
  private _success: string = ''
  private _assistants: any[] = []
  private _appointDays: any[] = []
  private _period: number = 30
  private listeners: Unsubscribe[] = []

  get savingProfile() {
    return this._saving
  }

  get busy() {
    return this._busy
  }

  get errorProfile() {
    return this._error
  }

  get success() {
    return this._success
  }

  get assistants() {
    return this._assistants
  }

  get appointDays() {
    return this._appointDays
  }

  get period() {
    return this._period
  }

  get disableAppoints() {
    return this.t2bUser?.disableAppoint
  }

  get currentUser() {
    return this._currentUser;
  }

  get t2bUser() {
    return this._t2bUser;
  }

  get awayMessage() {
    return this._t2bUser.awaySettings?.message || ''
  }

  get useAwayMessage() {
    return !!this._t2bUser.awaySettings?.useMessage
  }

  get isAdmin() {
    return this._t2bUser?.roles?.superAdmin || this._t2bUser?.roles?.admin || this._t2bUser?.admin
  }

  get isOwner() {
    return this._t2bUser?.roles?.superAdmin || this._t2bUser.superAdmin || this._t2bUser.owner;
  }

  get assignedContacts() {
    const userId = this._t2bUser?.id
    return directoryStore.allContacts.filter((item) => item.associateIDs?.includes(userId!!))
  }

  get visited() {
    return !!this._t2bUser.firstVisit
  }

  @Mutation
  public setBusy(busy: boolean) {
    this._busy = busy
  }

  @Mutation
  public setAwayMessage(message: string) {
    if (!this._t2bUser.awaySettings) {
      this._t2bUser.awaySettings = clone(defaultUser.awaySettings)
    }
    this._t2bUser.awaySettings!.message = message
  }

  @Mutation
  public setUseAwayMessage(useMessage: boolean) {
    if (!this._t2bUser.awaySettings) {
      this._t2bUser.awaySettings = clone(defaultUser.awaySettings)
    }
    this._t2bUser.awaySettings!.useMessage = useMessage
  }

  @Mutation
  public setSaving(value) {
    this._saving = value
    if (value) {
      this._error = ''
      this._success = ''
    }
  }

  @Mutation
  public setError(value) {
    this._error = value
  }

  @Mutation
  public setSuccess(value) {
    this._success = value
  }

  @Mutation
  public setAssistants(assistants: any[]) {
    this._assistants = assistants
  }

  @Mutation
  public setAppointDays(appointDays: any[]) {
    this._appointDays = appointDays
  }

  @Mutation
  public setTimePeriod(period: number) {
    this._period = period
  }

  @Mutation
  public setT2BUser(t2bUser) {
    this._t2bUser = t2bUser;
  }

  @Mutation
  public setCurrentUser(user) {
    this._currentUser = user;
  }

  @Mutation
  public addListener(listener: Unsubscribe) {
    this.listeners.push(listener)
  }

  @Action
  public async refreshUser() {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return
    }
    this.setCurrentUser(currentUser);
    const uid = currentUser.uid;
    const userSnapshot = await getDoc(user(uid));
    if (userSnapshot.exists()) {
      const data = userSnapshot.data() as AssociateAccount;
      this.updateUserData({id: uid, data})
    }
  }

  @Action
  public refreshCurrentUser(currentUser) {
    this.setCurrentUser(currentUser);
    return currentUser;
  }

  @Action
  public async shareProfileLink(associateId) {
    const businessId = applicationStore.businessId
    if (!associateId || !businessId) {
      return null;
    }
    const axiosResponse = await axios.post('/shortLinks', {
      dynamicLinkInfo: {
        domainUriPrefix: `https://${dynamicLinkConfig.actionCodeSettings.dynamicLinkDomain}/share/contact`,
        link: `${dynamicLinkConfig.actionCodeSettings.url}/businesses/${businessId}/users/${associateId}`,
        androidInfo: {
          androidPackageName: dynamicLinkConfig.actionCodeSettings.android!!.packageName
        },
        iosInfo: {
          iosBundleId: dynamicLinkConfig.actionCodeSettings.iOS!!.bundleId
        }
      },
      suffix: {
        option: 'SHORT'
      }
    }, {
      baseURL: 'https://firebasedynamiclinks.googleapis.com/v1',
      params: {key: appConfig.apiKey},
      headers: {
        ['Content-Type']: 'application/json'
      },
      validateStatus: (status) => status === 200
    });
    return axiosResponse.data.shortLink;
  }

  @Action
  public async generateContactLink(contactId) {
    const businessId = applicationStore.businessId
    if (!contactId || !businessId) {
      return null;
    }
    const axiosResponse = await axios.post('/shortLinks', {
      dynamicLinkInfo: {
        domainUriPrefix: `https://${dynamicLinkConfig.actionCodeSettings.dynamicLinkDomain}/share/contact`,
        link: `${dynamicLinkConfig.actionCodeSettings.url}/businesses/${businessId}/contacts/directory/${contactId}`,
        androidInfo: {
          androidPackageName: dynamicLinkConfig.actionCodeSettings.android!!.packageName
        },
        iosInfo: {
          iosBundleId: dynamicLinkConfig.actionCodeSettings.iOS!!.bundleId
        }
      },
      suffix: {
        option: 'SHORT'
      }
    }, {
      baseURL: 'https://firebasedynamiclinks.googleapis.com/v1',
      params: {key: appConfig.apiKey},
      headers: {
        ['Content-Type']: 'application/json'
      },
      validateStatus: (status) => status === 200
    });
    return axiosResponse.data.shortLink;
  }

  @Action
  public async muteProfile(duration) {
    const uid = this.t2bUser?.id
    if (!uid) {
      return
    }
    try {
      this.setSaving(true)
      let updateValue: any
      if (duration > 0) {
        const now = Timestamp.now();
        updateValue = new Timestamp(now.seconds + duration, now.nanoseconds)
      }
      if (duration === 0) {
        updateValue = deleteField()
      }
      await updateDoc(user(uid), 'doNotDisturb', updateValue)
      await this.refreshUser()
      this.setSaving(false)
      return true
    } catch (err: any) {
      this.setError(err.message)
      console.error(err)
    }
    this.setSaving(false)
    return false
  }

  @Action
  public async changeWorkingStatus(duration: number) {
    const uid = this.t2bUser?.id
    if (!uid) {
      return
    }
    try {
      this.setSaving(true)
      const userRef = user(uid);
      if (duration > 0) {
        await updateDoc(userRef, 'workingStatus', {
          autocancel: duration <= 24 * 60 * 60,
          duration: duration * 1000, // ms
          name: 'Absent',
          type: 2,
          time: Timestamp.now()
        })
      }
      if (duration === 0) {
        await updateDoc(userRef,
          'workingStatus.autocancel', deleteField(),
          'workingStatus.duration', deleteField(),
          'workingStatus.time', Timestamp.now(),
          'workingStatus.name', 'Active',
          'workingStatus.type', 1)
      }
      await this.refreshUser()
      this.setSaving(false)
      return true
    } catch (err: any) {
      this.setError(err.message)
      console.error(err)
    }
    this.setSaving(false)
    return false
  }

  @Action
  public async updateProfile(associate) {
    if (!associate) {
      return;
    }
    const data = Object.assign({}, associate);
    deleteProperty(data, 'id');
    try {
      this.setSaving(true)
      await updateDoc(user(associate.id),
        'position', data.position,
        'phoneNumber', data.phoneNumber.replace(/[^0-9]/g, '')
      );
      this.setSaving(false)
      return true
    } catch (err: any) {
      this.setError(err.message)
      console.error(err)
    }
    this.setSaving(false)
    return false
  }

  @Action
  public async saveAwayMessage(awayMessage?: string) {
    const userId = this.t2bUser?.id;
    if (!awayMessage || !userId) {
      return;
    }
    try {
      await updateDoc(user(userId), 'awaySettings.message', awayMessage)
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async saveUseMessage(useMessage?: boolean) {
    const userId = this.t2bUser?.id;
    if (useMessage === undefined || !userId) {
      return;
    }
    try {
      await updateDoc(user(userId), 'awaySettings.useMessage', useMessage)
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async addAppointmentAssistants(selectedAssociates: AssociateAccount[]) {
    const userId = this.t2bUser?.id;
    if (!selectedAssociates?.length || !userId) {
      return;
    }
    try {
      const associates = selectedAssociates.map((associate) => {
        const item: any = {
          id: associate.id,
          fullName: associate.fullName,
          email: associate.email
        };
        if (associate.phoneNumber) {
          item.phoneNumber = associate.phoneNumber
        }
        if (associate.position) {
          item.position = associate.position
        }
        if (associate.photoUrl) {
          item.photoUrl = associate.photoUrl
        }
        if (associate.defaultContactId) {
          item.defaultContactId = associate.defaultContactId
        }
        if (associate.status) {
          item.status = associate.status
        }
        return item
      })
      const batch = writeBatch(firestore)
      for (const associate of associates) {
        batch.set(userAssistant(userId, associate.id), associate)
      }
      await batch.commit()
      // this.setAssistants([this.assistants, ...associates])
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async loadPermissionsSettings() {
    const userId = this.t2bUser?.id;
    if (!userId) {
      return;
    }
    try {
      let snapshot = await getDocs(userAppointDays(userId));
      await this.processAppointDaysSnapshot(snapshot)
      snapshot = await getDocs(userAssistants(userId));
      this.setAssistants(snapshot.docs.map((item) => {
        return {id: item.id, ...item.data()}
      }) || [])
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async processAppointDaysSnapshot(snapshot: QuerySnapshot) {
    const days: any[] = snapshot.docs.map((item) => {
      return {name: item.id, ...item.data()}
    }).sort((a, b) => daysOrder[a.name] - daysOrder[b.name])
    if (!days.length) {
      await this.setDefaultAppointDays()
    } else {
      this.setAppointDays(days)
      this.setTimePeriod(days[0].period)
    }
  }

  @Action
  public async removeAssistant(associateId: string) {
    const userId = this.t2bUser?.id;
    if (!userId) {
      return;
    }
    try {
      await deleteDoc(userAssistant(userId, associateId))
      this.setAssistants(this.assistants.filter((item) => item.id !== associateId))
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async changeAppointDay(day: AppointDay) {
    const userId = this.t2bUser?.id;
    if (!userId) {
      return;
    }
    try {
      await setDoc(userAppointDay(userId, day.name), day, {merge: true})
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async setDefaultAppointDays() {
    const userId = this.t2bUser?.id;
    if (!userId) {
      return;
    }
    const appointDays = userAppointDays(userId);
    const batch = writeBatch(firestore)
    try {
      for (const day of appointDaysDefault) {
        batch.set(userAppointDayByRef(appointDays, day.name),
          {
            from: day.from,
            to: day.to,
            start: day.start,
            end: day.end,
            active: day.active,
            period: day.period
          })
      }
      await batch.commit()
      this.setAppointDays(appointDaysDefault)
      this.setTimePeriod(appointDaysDefault[0].period)
    } catch (e) {
      console.error(e)
    }
  }

  @Action
  public async setAppointDaysPeriod(period: number) {
    const userId = this.t2bUser?.id;
    if (!userId) {
      return;
    }
    const appointDaysRef = userAppointDays(userId);
    const batch = writeBatch(firestore)
    try {
      for (const day of appointDaysDefault) {
        batch.update(userAppointDayByRef(appointDaysRef, day.name), 'period', period)
      }
      await batch.commit()
    } catch (e) {
      console.error(e)
    }
  }

  @Action
  public async disableAppointments(duration) {
    const uid = this.t2bUser?.id
    if (!uid) {
      return
    }
    try {
      let updateValue: any
      if (duration > 0) {
        const now = Timestamp.now();
        updateValue = new Timestamp(now.seconds + duration, now.nanoseconds)
        this.t2bUser!.disableAppoint = updateValue
      }
      if (duration === 0) {
        updateValue = deleteField()
        this.t2bUser!.disableAppoint = null
      }
      await updateDoc(user(uid), 'disableAppoint', updateValue)
      await this.refreshUser()
    } catch (err: any) {
      this.setError(err.message)
      console.error(err)
    }
  }

  @Action
  public startListening() {
    const uid = this.t2bUser?.id
    if (!uid) {
      return
    }
    const userListener = onSnapshot(user(uid),
      (snapshot) => {
        if (snapshot != null && snapshot.exists) {
          const data = snapshot.data() as AssociateAccount
          this.updateUserData({id: uid, data})
        }
      });
    this.addListener(userListener)
    const appointDaysListener = onSnapshot(userAppointDays(uid),
      async (snapshot) => {
        await this.processAppointDaysSnapshot(snapshot)
      });
    this.addListener(appointDaysListener)

    const assistantsListener = onSnapshot(userAssistants(uid),
      (snapshot) => {
        this.setAssistants(snapshot.docs.map((item) => {
          return {id: item.id, ...item.data()}
        }) || [])
      });
    this.addListener(assistantsListener)
    console.log('ProfileStore => started listening')
  }

  @Mutation
  public releaseListeners() {
    this.listeners.forEach((unsubscribe) => unsubscribe())
    this.listeners = []
    console.log('ProfileStore => stopped listening')
  }

  @Action
  public async updatePassword(params: { password: string; newPassword: string }): Promise<boolean> {
    this.setSaving(true)
    let currentUser = auth.currentUser;
    let credentials
    try {
      credentials = await reauthenticateWithCredential(currentUser!, EmailAuthProvider.credential(currentUser!.email!, params.password));
    } catch (err: any) {
      console.error(err)
      this.setError(err.message)
      this.setSaving(false)
      return false
    }
    if (credentials?.user) {
      currentUser = credentials!.user
      try {
        await updatePassword(currentUser!, params.newPassword)
        this.setSuccess('Password has been updated')
        this.setSaving(false)
        return true
      } catch (err: any) {
        console.error(err)
        this.setError(err.message)
        this.setSaving(false)
      }
    }
    return false
  }

  @Action
  public async unblockUser(customerId: string) {
    const request: UnblockUserRequest = {
      customerId,
      associateId: this._t2bUser.id!,
      businessId: this._t2bUser.business!.id!
    }
    if (await unblockUser(request)) {
      if (router.currentRoute.name === 'blocked-customer-profile') {
        await this.context.dispatch('selectCustomer', null)
        await router.push({name: 'block-list'})
      }
      notificationsStore.showInfo('User has been unblocked')
    } else {
      notificationsStore.showIssue('Failed to unblock user')
    }
  }

  @Mutation
  public updateUserData(userData: { id: string, data: AssociateAccount }) {
    const {id, data} = userData
    this._t2bUser.id = id
    this._t2bUser.email = data.email
    this._t2bUser.fullName = data.fullName
    this._t2bUser.position = data.position
    this._t2bUser.phoneNumber = data.phoneNumber
    this._t2bUser.defaultContactId = data.defaultContactId
    this._t2bUser.admin = data.admin
    this._t2bUser.superAdmin = data.superAdmin
    this._t2bUser.doNotDisturb = data.doNotDisturb
    this._t2bUser.disableAppoint = data.disableAppoint
    this._t2bUser.photoUrl = data.photoUrl
    this._t2bUser.firstVisit = data.firstVisit
    if (data.business) {
      this._t2bUser.business = data.business
    }
    if (data.awaySettings) {
      this._t2bUser.awaySettings = {
        message: data.awaySettings.message || '',
        useMessage: data.awaySettings.useMessage
      }
    }
    if (data.workingStatus) {
      this._t2bUser.workingStatus = data.workingStatus
    }
    if (data.status) {
      this._t2bUser.status = data.status
    }
    if (data.stats) {
      this._t2bUser.stats = data.stats
    }
    if (data.roles) {
      this._t2bUser.roles = data.roles
    }
  }

  @Mutation
  public setFirstVisit(time: Timestamp) {
    this._t2bUser.firstVisit = time
  }

  @Action
  public async firstVisit() {
    if (this.visited) {
      return
    }
    const uid = this.t2bUser?.id
    if (!uid) {
      return
    }
    const visitedTime = Timestamp.now();
    try {
      await updateDoc(user(uid), 'firstVisit', visitedTime);
      this.setFirstVisit(visitedTime)
    } catch (e) {
      console.error('failed set first visit time', e)
    }
  }

  @Action
  public attachStatus() {
    const uid = this.t2bUser?.id;
    if (!uid) {
      return
    }
    const userStatusDatabaseRef = ref(database, `/status/${uid}`)
    onValue(ref(database, '.info/connected'),
      async (snapshot) => {
        if (snapshot.val() === false) {
          console.log('local .info/connected: false')
          return
        }
        const appId = firebaseApp.options.appId;
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const platform = 'web'
        const lastChanged = serverTimestamp()
        await update(userStatusDatabaseRef, {
          online: true,
          lastChanged,
          tz,
          platform,
          appId,
          loggedIn: true
        })
        await onDisconnect(userStatusDatabaseRef).update({
          online: false,
          lastChanged,
          tz,
          platform,
          appId,
          loggedIn: !!auth.currentUser
        })
      })
  }

  @Action
  public async detachStatus() {
    off(ref(database, '.info/connected'), 'value')
    const uid = this.t2bUser?.id;
    const userStatusDatabaseRef = ref(database, `/status/${uid}`)
    await update(userStatusDatabaseRef, {
      online: false,
      lastChanged: serverTimestamp(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      platform: 'web',
      appId: firebaseApp.options.appId,
      loggedIn: false
    })
  }
}

export const profileStore = getModule(ProfileModule);
